#display {
  position: relative;
  width: 100%;
  height: auto;
}

#display > img {
  margin-left: 50%;
  transform: translateX(-50%);
  min-height: 110vh;
  max-height: 150vh;
  min-width: 90vw;
  max-width: none;
  height: auto;
  overflow-x: hidden;
}

#displayTextWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

#displayText {
  padding: 2rem 1.25rem;
  font-size: 5rem;
  font-family: "Indie Flower", cursive;
  color: white;
  border: 5px solid var(--green);
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 22%;
  height: auto;
}

@media only screen and (max-width: 700px) {
  #displayText {
    font-size: 3rem;
    padding: 0.3rem 0.3rem 1.25rem 0.3rem;
    top: 14%;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1050px) {
  #displayText {
    top: 18%;
    padding: 0.5rem 0.5rem 1.5rem 0.5rem;
    width: 80%;
    justify-self: center;
  }
}
